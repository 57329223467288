<template>
  <div class="visitorLevel-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_visitorLevel"
        :hoverInfo="translations.labels.button_add_visitorLevel_hover"
        :onClick="newItem"
      />
    </Action-panel>

    <TableList :is-ready="true" :items="model" :headers="headers" />
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { toastService } from "@/services/toastService.js";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";
import TableAction from "@/components/table/TableAction";

@Component({
  inject: {
    visitorLevelService: "visitorLevelService",
    toastService: "toastService",
  },
  components: {
    TableAction,
    ActionPanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList,
  },
})
export default class VisitorLevelList extends Vue {
  isPageReady = false;
  model = [];
  search = "";
  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.visitorLevel_table_code,
        value: "code",
      },
      {
        text: this.translations.labels.visitorLevel_table_systemLevel,
        value: "isSystem",
        type: "boolean",
        itemValue:(item)=>{return item.isSystem;}
      },
      {
        text: this.translations.labels.table_detail,
        value: "detail",
        type: "link",
        icon: "mdi-account-star",
        formatValue: (item) => {
          return `/visitor-level/detail/${item.id}`;
        },
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "action",
        icon: "mdi-pencil",
        isVisible:(item)=>{return !item.isSystem;},
        onAction: (item) => {
          this.edit(item);
        },
      },
      {
        text: this.translations.labels.table_enable,
        value: "isEnabled",
        type: "switch",
        isVisible:(item)=>{return !item.isSystem;},
        itemValue:(item)=>{return item.isEnabled;},
        onAction: (item) => {
          this.swapEnable(item);
        },
      },
    ];
  }

  newItem() {
    this.$router.push("/visitor-level/new");
  }
  edit(item) {
    if (item.isSystem) {
      toastService.error(this.translations.errors.cannotEdit_visitorLevel);
      return;
    }
    this.$router.push(`/visitor-level/edit/${item.id}`);
  }

  async swapEnable(item) {
    if (!item.isDeletable) {
      toastService.error(this.translations.errors.cannotDisable_visitorLevel);
      return;
    }

    const status = item.isEnabled ? "disable" : "enable";
    try {
      await this.visitorLevelService.setEnabling(item.id, status);
      this.model = await this.visitorLevelService.list();
      this.toastSuccessLabel(item.isEnabled);
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  toastSuccessLabel(isEnabled) {
    isEnabled
      ? toastService.success(this.translations.success.visitorLevel_disable)
      : toastService.success(this.translations.success.visitorLevel_enable);
  }

  async init() {
    try {
      this.model = await this.visitorLevelService.list();
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  mounted() {
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.visitorLevel-list {
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .action-button {
    cursor: pointer;
  }
}
</style>
